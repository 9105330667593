import React from 'react';
import ReactDOM from 'react-dom/client';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<div id="container">
              <img src="logo.png"/>
              <table style={{marginTop:"40px"}} height="300px">
              <tr>
              <td class="container">
              <p style={{color:'#a8a8a8',fontSize:"1.5em"}}>The ultimate student resource for PESites. Here you can access the course material including previous year question papers of BTech CSE Programme.</p>
              <br/>
              <button>Start</button>
              </td>
              <td class="container">
              <p style={{color:'#a8a8a8',fontSize:"1.5em"}}>Join the PES Global Chat</p>
              <form method="POST" action="/setUsername"><input type="text" placeholder="Enter Your Name" id="name" name="name"/> <button type="submit">Go</button></form>
              </td>
              </tr>
              </table>
            </div>);
